// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderFloorEight_flatCover__w6xKs {
    opacity: 0.35;
    cursor: pointer;
}

.RenderFloorEight_flatCover__w6xKs:hover {
    cursor: pointer;
    opacity: 1;
}

.RenderFloorEight_duplexOff__N9qLW {
    opacity: 0.35;
    cursor: pointer;
}

.RenderFloorEight_duplexOn__-rNQ6 {
    cursor: pointer;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/FloorRenders/RenderFloorEight/RenderFloorEight.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,UAAU;AACd;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".flatCover {\n    opacity: 0.35;\n    cursor: pointer;\n}\n\n.flatCover:hover {\n    cursor: pointer;\n    opacity: 1;\n}\n\n.duplexOff {\n    opacity: 0.35;\n    cursor: pointer;\n}\n\n.duplexOn {\n    cursor: pointer;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flatCover": `RenderFloorEight_flatCover__w6xKs`,
	"duplexOff": `RenderFloorEight_duplexOff__N9qLW`,
	"duplexOn": `RenderFloorEight_duplexOn__-rNQ6`
};
export default ___CSS_LOADER_EXPORT___;
