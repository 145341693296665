// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-slider {
    width: 100%;
    height: 10px;
    position: relative;
    margin: 20px 0;
}

.track {
    position: absolute;
    top: 50%;
    height: 10px;
    background: #ddd;

}

.thumb {
    height: 20px;
    width: 20px;
    background-color: #1385d8;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -5px;
}

.track-1 {
    background: #1385d8;
    height: 10px;
    transform: translateY(0);
}

.thumb-value {
    position: absolute;
    top: -25px; /* Adjust based on the height of the thumb and desired spacing */
    left: 50%;
    transform: translateX(-50%);
    background-color: #1385d8;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ApartmentsFilter/ApartmentsFilter.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,gBAAgB;;AAEpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,UAAU,EAAE,gEAAgE;IAC5E,SAAS;IACT,2BAA2B;IAC3B,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".horizontal-slider {\n    width: 100%;\n    height: 10px;\n    position: relative;\n    margin: 20px 0;\n}\n\n.track {\n    position: absolute;\n    top: 50%;\n    height: 10px;\n    background: #ddd;\n\n}\n\n.thumb {\n    height: 20px;\n    width: 20px;\n    background-color: #1385d8;\n    border-radius: 50%;\n    cursor: pointer;\n    position: absolute;\n    top: 50%;\n    margin-top: -5px;\n}\n\n.track-1 {\n    background: #1385d8;\n    height: 10px;\n    transform: translateY(0);\n}\n\n.thumb-value {\n    position: absolute;\n    top: -25px; /* Adjust based on the height of the thumb and desired spacing */\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: #1385d8;\n    color: white;\n    padding: 2px 5px;\n    border-radius: 3px;\n    font-size: 12px;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
