// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderFloorOne_flatCover__8BJKj {
    opacity: 0.35;
    cursor: pointer;
}

.RenderFloorOne_flatCover__8BJKj:hover {
    cursor: pointer;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/FloorRenders/RenderFloorOne/RenderFloorOne.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".flatCover {\n    opacity: 0.35;\n    cursor: pointer;\n}\n\n.flatCover:hover {\n    cursor: pointer;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flatCover": `RenderFloorOne_flatCover__8BJKj`
};
export default ___CSS_LOADER_EXPORT___;
